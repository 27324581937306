var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container--fixed-md"},[_c('PageToolbar',[_c('v-breadcrumbs',{attrs:{"large":"","items":_vm.breadcrumbs,"divider":">"}}),_c('v-spacer'),_c('DateRangePicker',{attrs:{"date-range":_vm.dateRange,"range-days":1}})],1),_c('v-container',{staticClass:"py-4",attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndDown}},[(!_vm.monitor.data)?_c('v-skeleton-loader',{attrs:{"type":"card","height":"800"}}):[_c('v-row',{staticClass:"mb-2 px-4 text-subtitle-2 text-center",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"grey--text font-weight-regular"},[_vm._v("Last check at")]),(_vm.monitor.data.updatedAt)?_c('XDate',{attrs:{"date":_vm.monitor.data.updatedAt,"format":"relative"}}):_c('div',[_vm._v("None")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"grey--text font-weight-regular"},[_vm._v("State")]),_c('div',[_c('MonitorStateAvatar',{attrs:{"state":_vm.monitor.data.state}})],1)]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.monitor.data.state != _vm.MonitorState.Paused)?_c('v-btn',{attrs:{"loading":_vm.monitorMan.pending,"depressed":"","small":"","title":"Pause monitor"},on:{"click":function($event){return _vm.pauseMonitor(_vm.monitor)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pause")]),_vm._v(" Pause monitor ")],1):_c('v-btn',{attrs:{"loading":_vm.monitorMan.pending,"depressed":"","small":"","title":"Resume monitor"},on:{"click":function($event){return _vm.activateMonitor(_vm.monitor)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-play")]),_vm._v(" Resume monitor ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"depressed":"","small":"","to":{
              name: 'AlertList',
              query: {
                q: 'monitor:' + _vm.monitor.data.id,
                state: null,
              },
            }}},[_vm._v(" View alerts ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('MonitorMetricForm',{attrs:{"date-range":_vm.dateRange,"metrics":_vm.metrics,"monitor":_vm.reactive(_vm.monitor.data)},on:{"click:save":_vm.onSave,"click:cancel":_vm.onCancel}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./XPagination.vue?vue&type=template&id=e2fd7604&scoped=true&"
import script from "./XPagination.vue?vue&type=script&lang=ts&"
export * from "./XPagination.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.73.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2fd7604",
  null
  
)

export default component.exports